import { sendHttp } from "./http/http";

const getApiZx = async () => {
    let res = await sendHttp("get", "/data1");
    return res.data
}
const getApiBg = async () => {
    let res = await sendHttp("get", "/data2");
    return res.data
}


export {
    getApiZx,
    getApiBg
}