import axios from 'axios';
import { message } from 'ant-design-vue';

axios.defaults.baseURL = 'http://139.155.181.9:8029/api/v1/test/'; // 域名
let basUrl = 'http://139.155.181.9:8029/api/v1/test/'

axios.defaults.timeout = 8000; // 请求时间 超过这个时间就会停止请求

// let token = localStorage.getItem("token")

// // interceptors 拦截 请求拦截  
// axios.interceptors.request.use((config) => {
//     config.headers["authori-zation"] = token;
//     return config
// }, (err) => {
//     console.log("拦截失败", err)
// })

// 响应拦截
axios.interceptors.response.use((res) => {
    if (res.data.code === 401) {
        message.error('登录失效，请重新登录')
        setTimeout(() => {
            window.location.href = '/'
        }, 2000)
    }
    return res
}, (err) => {
    console.log("拦截失败", err)
    // window.localStorage.removeItem("token")
    // message.error('登录失效，请重新登录')
    // setTimeout(() => {
    //     window.location.href = '/'
    // }, 500)
})

let sendHttp = (method, url, data = null) => {
    return new Promise((resolve, reject) => {
        let defaultHeader = { // 请求头
        }
        return axios({
            url: url,
            method: method,
            params: method === 'get' ? data : null,
            data: method === 'post' ? data : null,
            headers: defaultHeader
        }).then(response => {
            // console.log(response);
            resolve(response); // 将axios请求的结果传递给Promise的resolve函数
        }).catch(error => {
            // console.log(error);
            reject(error); // 将axios请求的错误传递给Promise的reject函数
        });
    })
}

export {
    sendHttp,
    basUrl
};