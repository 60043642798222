 
<template>
  <e-charts class="chart" :option="option" />
</template>
  
  <script setup>
import { computed } from "vue";

//模拟数据value的字段对应Y轴，name字段对应X轴
// const data = ref([
//   { value: 11, name: "A" },
//   { value: 31, name: "B" },
//   { value: 75, name: "C" },
//   { value: 25, name: "D" },
//   { value: 16, name: "E" },
// ]);

const option = computed(() => {
  return {
    tooltip: {
      trigger: "item",
      axisPointer: { type: "shadow" }, // 触发类型为轴指示器
    },
    xAxis: {
      type: "category",
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], // x 轴的类目
    },
    yAxis: {
      type: "value", // y 轴为值轴
    },
    series: [
      {
        type: "bar", // 柱状图类型
        data: [120, 200, 150, 80, 70, 110, 130], // 柱状图数据
        itemStyle: {
          color: "#a5cec2", // 设置柱子颜色
        },
        barWidth: "40%", // 设置柱子的宽度
      },
    ],
  };
});
</script>
  
   
  <style scoped>
.chart {
  height: 100%;
  width: 90%;
}
</style>