 
<template>
  <e-charts class="chart" :option="option" />
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { getApiZx } from "../api.js";

onMounted(async () => {
  const res = await getApiZx();
  data.value = res.data;
});

//模拟数据value的字段对应Y轴，name字段对应X轴
const data = ref([]);

const option = computed(() => {
  return {
    xAxis: {
      type: "category",
      data: data.value.map((v) => v.time),
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        type: "line",
        data: data.value.map((v) => v.value),
        lineStyle: {
          color: "#a5cec2", // 设置折线颜色
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: "#7ecfc8" },
              { offset: 1, color: "rgba(165, 206, 194, 0)" },
            ],
            global: false,
          },
        },
        symbol: "none",
      },
    ],
  };
});
</script>

 
<style scoped>
.chart {
  height: 100%;
  width: 90%;
}
</style>