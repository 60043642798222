<template>
  <a-layout class="box">
    <a-layout-sider
      v-model:collapsed="collapsed"
      :trigger="null"
      collapsible
      class="bjmenu"
    >
      <div class="logo">仅供学习使用</div>
      <a-menu
        v-model:selectedKeys="selectedKeys"
        theme="dark"
        mode="inline"
        class="bjmenu"
      >
        <a-menu-item key="1">
          <user-outlined />
          <span>nav 1</span>
        </a-menu-item>
        <a-menu-item key="2">
          <video-camera-outlined />
          <span>nav 2</span>
        </a-menu-item>
        <a-menu-item key="3">
          <upload-outlined />
          <span>nav 3</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <menu-unfold-outlined
          v-if="collapsed"
          class="trigger"
          @click="() => (collapsed = !collapsed)"
        />
        <menu-fold-outlined
          v-else
          class="trigger"
          @click="() => (collapsed = !collapsed)"
        />
      </a-layout-header>
      <a-layout-content
        :style="{
          margin: '24px 16px',
          padding: '24px',
          background: '#f5f5f5',
          minHeight: '280px',
        }"
      >
        <div>首页</div>
        <div :style="{ display: 'flex', alignItems: 'center' }">
          <div
            :style="{
              display: 'flex',
              alignItems: 'center',
              width: '200px',
              marginRight: '10px',
            }"
          >
            XXX: <a-input v-model:value="value" placeholder="Basic usage" />
          </div>
          <div>
            XXX:
            <a-select
              v-model:value="value1"
              :size="size"
              style="width: 200px"
              :options="options"
            ></a-select>
          </div>
        </div>
        <div class="main">
          <div class="main-top">
            <div class="main-top-item">
              <div class="main-top-item-img">
                <img src="./assets/cdz.png" alt="" />
              </div>
              <div>
                <div class="main-top-item-title">XXXXXXXX</div>
                <div class="main-top-item-num">
                  12314124 <span :style="{ color: '#000' }">单位</span>
                </div>
              </div>
            </div>
            <div class="main-top-item">
              <div class="main-top-item-img">
                <img src="./assets/cdz.png" alt="" />
              </div>
              <div>
                <div class="main-top-item-title">XXXXXXXX</div>
                <div class="main-top-item-num">
                  12314124 <span :style="{ color: '#000' }">单位</span>
                </div>
              </div>
            </div>
            <div class="main-top-item">
              <div class="main-top-item-img">
                <img src="./assets/cdz.png" alt="" />
              </div>
              <div>
                <div class="main-top-item-title">XXXXXXXX</div>
                <div class="main-top-item-num">
                  12314124 <span :style="{ color: '#000' }">单位</span>
                </div>
              </div>
            </div>
            <div class="main-top-item">
              <div class="main-top-item-img">
                <img src="./assets/cdz.png" alt="" />
              </div>
              <div>
                <div class="main-top-item-title">XXXXXXXX</div>
                <div class="main-top-item-num">
                  12314124 <span :style="{ color: '#000' }">单位</span>
                </div>
              </div>
            </div>
          </div>
          <div class="main-bottom">
            <div class="main-bottom-left">
              <div class="main-bottom-left-top">
                <div class="main-title">XXXXXX</div>
                <ZheXian></ZheXian>
              </div>
              <div class="main-bottom-left-bottom">
                <div class="main-title">XXXXXX</div>
                <ZhuZhuang></ZhuZhuang>
              </div>
            </div>
            <div class="main-bottom-right">
              <a-table :dataSource="dataSource" :columns="columns" bordered />
            </div>
          </div>
        </div>
      </a-layout-content>
      <a-layout-footer style="text-align: center">
        <div class="icpclass" @click="goICP">豫公网安备41018202000898号</div>
        <div class="icpclass" @click="goICP">豫ICP备2024042974号</div>
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons-vue";
import ZheXian from "./components/ZheXian.vue";
import ZhuZhuang from "./components/ZhuZhuang.vue";
import { getApiBg } from "./api.js";

onMounted(async () => {
  const data = await getApiBg();
  dataSource.value = data.data;
});

const selectedKeys = ref(["1"]);
const collapsed = ref(false);

const columns = ref([
  {
    title: "名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "值",
    dataIndex: "value",
    key: "value",
  },
  {
    title: "状态",
    dataIndex: "state",
    key: "state",
  },
]);

const goICP = () => {
  window.open("https://beian.miit.gov.cn/#/Integrated/index");
};

const dataSource = ref([]);
</script>
<style scoped>
.box {
  height: 100vh;
  margin: 0;
}
.trigger {
  font-size: 18spx;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #fff;
}

.site-layout .site-layout-background {
  background: #fff;
}
.bjmenu {
  background: #046c6c;
}
/* .ant-menu-item .ant-menu-item-selected {
  background: #01595a !important;
} */
::v-deep
  :where(.css-dev-only-do-not-override-17yhhjv).ant-menu-dark
  .ant-menu-item-selected {
  background-color: #01595a !important;
}
::v-deep .ant-layout-content {
  margin: 0 !important;
}

.main-top {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-top-item {
  width: 24.5%;
  height: 8vh;
  background: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
}
.main-top-item-title {
  margin-top: 10px;
}
.main-top-item-num {
  margin-top: 10px;
  font-weight: 600;
  color: blue;
}
.main-top-item-img {
  height: 100%;
  width: 30%;
  position: relative;
}
.main-top-item-img img {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(10%, -50%);
}
.main-bottom {
  height: 75vh;
  display: flex;
}
.main-bottom-left {
  width: 49%;
}
.main-bottom-right {
  width: 50%;
  padding: 10px;

  background: #fff;
  margin-left: 10px;
}
.main-bottom-left-top {
  padding: 10px;
  height: 49%;
  background: #fff;
  margin-bottom: 10px;
}
.main-bottom-left-bottom {
  padding: 10px;
  height: 49%;
  background: #fff;
  margin-top: 10px;
}
.main-title {
  border-left: 5px solid #01595a;
  padding-left: 10px;
}
.icpclass {
  color: blue;
  /* border-bottom: 1px #000 solid; */
}
.icpclass:hover {
  cursor: pointer;
}
</style>