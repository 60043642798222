import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/reset.css';

// 引入echarts
import Echarts from 'vue-echarts'
import * as echarts from 'echarts'
const app = createApp(App);


// 使用组件
app.component('e-charts',Echarts)
// 全局挂载 echarts
app.config.globalProperties.$echarts = echarts




app.use(Antd).mount('#app');